import React, { FC } from 'react';
import { Badge, Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { AddHomeWorkOutlined, History, LockClockRounded } from '@mui/icons-material';
import { MUIDataTableOptions } from 'mui-datatables';
import { format, isBefore } from 'date-fns';

import { Link, useNavigate } from 'react-router-dom';
import { StyledTerritoriesTable } from './StyledTerritoriesTable';
import { useAll, usePublishers } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Maybe, Scalars, TerritoryData, UserFields, UserPermissions } from '../../types';
import { TerritoryFilterData, territoryTableFilter } from '../../constant';
import {
  getSetLocalStorage,
  getWarnEndTerritoryDates,
  isPermissionsIncludes,
  getLockTerritoryDate,
} from '../../helpers';
import { Icon } from '../../legos';

type Props = {
  filter: TerritoryFilterData;
  setFilter: React.Dispatch<React.SetStateAction<TerritoryFilterData>>;
};

export const TerritoriesTable: FC<Props> = ({ filter, setFilter }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [favorite] = getSetLocalStorage<Array<Maybe<Scalars['ID']> | undefined>>('favorite', []);

  const { data: territories } = useAll<TerritoryData[]>('/territories');
  const { getPublisher } = usePublishers();

  const filteredTerritories = territories
    ?.filter(territory => filter.eq(territory, favorite))
    .sort((a, b) => filter.sort(a, b));

  const processedTerritoriesFilter = territoryTableFilter.find(
    ({ value }) => value === 'processed'
  );
  const expiredProcessedTerritories =
    territories?.filter(territory => {
      const today = new Date();
      const { endDate } = getWarnEndTerritoryDates(new Date(territory.start_at ?? 0));

      return processedTerritoriesFilter?.eq(territory) && endDate && isBefore(endDate, today);
    }) ?? [];

  const handleChange = (e: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue !== null) {
      const newFilterIndex = territoryTableFilter.findIndex(({ value }) => value === newValue);
      setFilter(territoryTableFilter[newFilterIndex]);
    }
  };

  const columns = [
    {
      name: 'id',
      label: t('id'),
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'congregation_id',
      label: t('congregation_id'),
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'code',
      label: t('code'),
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (code: string, { rowData }: { rowData: string[] }) => {
          const { lockDate, isAfterLockDate } = getLockTerritoryDate(new Date(rowData.at(-1) ?? 0));
          const lockDateString = `${format(lockDate, 'd')} ${t(format(lockDate, 'LLLL'))} ${format(lockDate, 'Y')}`;

          return (
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
              {code}
              {isAfterLockDate && (
                <Tooltip
                  arrow
                  color="primary"
                  placement="top"
                  title={isAfterLockDate && t('territoryLocked', { date: lockDateString })}
                >
                  <LockClockRounded color="primary" />
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    },
    {
      name: 'street',
      label: t('street'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'house',
      label: t('house'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'entrance',
      label: t('entrance'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'user_id',
      label: t('Publisher'),
      options: {
        filter: true,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
        customBodyRender: (id: Maybe<Scalars['ID']>) => {
          const publisher = getPublisher(id);
          return publisher
            ? `${publisher[UserFields.LastName]} ${publisher[UserFields.FirstName]}`
            : '';
        },
      },
    },
    {
      name: 'start_at',
      label: t('start_at2'),
      options: {
        filter: false,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
      },
    },
    {
      name: 'finish_at',
      label: t('finish_at'),
      options: {
        filter: false,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    responsive: 'vertical',
    selectableRows: 'none',
    filterType: 'multiselect',
    onRowClick: row => navigate(`/territory/${row[0]}`),
    setRowProps: row => {
      const today = new Date();
      const startDate = new Date(row[7]);
      const { warnDate, endDate } = getWarnEndTerritoryDates(startDate);

      if (endDate && isBefore(endDate, today)) {
        return {
          className: 'MuiTableRow-Expired',
        };
      }

      if (warnDate && isBefore(warnDate, today)) {
        return {
          className: 'MuiTableRow-Warn',
        };
      }

      return {};
    },
    textLabels: {
      pagination: {
        next: t('MUITablePaginationNext'),
        previous: t('MUITablePaginationPrevious'),
        rowsPerPage: t('MUITablePaginationRowsPerPage'),
        displayRows: t('MUITablePaginationDisplayRows'),
      },
    },
  };

  return (
    <>
      <Stack mt={2} direction="row" justifyContent="space-between" gap={1} flexWrap="wrap">
        {isPermissionsIncludes(UserPermissions.TerritoryEditor) && (
          <Stack direction="row" gap={1}>
            <Link to="/territory/create">
              <Button variant="outlined">
                <AddHomeWorkOutlined fontSize="large" />
              </Button>
            </Link>
            <Link to="/territories/history">
              <Button variant="outlined">
                <History fontSize="large" />
              </Button>
            </Link>
          </Stack>
        )}
        <ToggleButtonGroup color="primary" value={filter.value} exclusive onChange={handleChange}>
          {territoryTableFilter.map(({ value, icon }) => (
            <Badge
              key={value}
              max={10}
              badgeContent={expiredProcessedTerritories.length}
              color="error"
              invisible={value !== 'processed'}
            >
              <ToggleButton
                value={value}
                color={
                  value === 'processed' && expiredProcessedTerritories.length ? 'error' : 'primary'
                }
              >
                {icon ? <Icon icon={icon} /> : t(value)}
              </ToggleButton>
            </Badge>
          ))}
        </ToggleButtonGroup>
      </Stack>
      {filteredTerritories && (
        <Stack my={1}>
          <StyledTerritoriesTable
            title={t('territories')}
            data={filteredTerritories}
            columns={columns}
            options={options}
          />
        </Stack>
      )}
    </>
  );
};
