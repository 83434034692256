import { Scalars, UserPermissions } from '../types';

type JWTData = {
  id?: Scalars['Int'];
  permissions?: UserPermissions[];
  isAllowed4Stand?: Scalars['Switch'];
  territory_hide_for_days?: Scalars['Int'];
};

export const getJwtData = (): JWTData => {
  if (typeof localStorage === 'undefined') {
    // console.log('-------------localStorage is not available')
    return {};
  }

  const jwt = localStorage.getItem('jwt');
  if (!jwt || jwt === 'undefined' || jwt === 'null' || jwt === '') {
    // console.log('--------------JWT token is not found in local storage')
    return {};
  }

  try {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    // console.log('--------------Error decoding JWT token', e)
    return {};
  }
};
